import { CookieModal, Themed, compensaTheme } from '@sky-distribution/react-commons-ui';
import React from 'react';
import { createRoot } from 'react-dom/client';

const rootElement = document.createElement('div');
rootElement.setAttribute('id', 'bee_cookie_widget');

const root = createRoot(rootElement);

root.render(
   <Themed theme={compensaTheme}>
     <CookieModal language='pl' tenant='compensa' />
   </Themed>
 );

document.body.appendChild(rootElement);
